import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { SiteService } from 'src/app/services/site.service';
import { errorFetchDeltaSiteCache, fetchAllSites, fetchDeltaSiteCache, mappingAllSitesCompanies, mappingSomeSitesCompanies, successFetchAllSites, successUpdateSites } from '../actions/site.action';
import { selectCompaniesState$ } from '../selectors/company.selectors';
import { Injectable } from '@angular/core';
import { successUpdateParentFamilies } from '../actions/parent-family.action';
import { selectParentFamiliesBySitesIds$ } from '../selectors/parent-family.selectors';
import { CacheService, ObjectStore } from 'src/app/services/cache.service';
import { Site } from 'src/app/models/site';

@Injectable()
export class SiteEffects {
    fetchAllSites$ = createEffect(() =>
        this.actions$.pipe(
          ofType(fetchAllSites),
          switchMap(({ payload }) => {
            const fetchSites$ = payload && payload.lastRefreshTime ?
              this.siteService.getAllSites(payload.lastRefreshTime).pipe(
                switchMap(sites =>
                    this._cacheService.deleteFromCache(sites.deletedData, ObjectStore.Sites).pipe(
                      switchMap(() =>
                        this._cacheService.updateCache([...sites.updatedData, ...sites.createdData], ObjectStore.Sites, true).pipe(
                          map((updatedData: Site[]) => successFetchAllSites({ sites: updatedData })),
                          catchError(error => of(error))
                        )
                      )
                    )
                  )
                ) :
              this.siteService.getAllSites().pipe(
                tap(sites => this._cacheService.cacheState(sites.createdData, ObjectStore.Sites)),
                map(sites => successFetchAllSites({ sites: sites.createdData })),
                catchError(error => of(error))
              );

            return fetchSites$.pipe(
              catchError(error => {
                console.error('Error fetching sites:', error);
                return of(error);
              })
            );
          })
        )
      );



    public successFetchAllSites$ = createEffect(() => { return this.actions$.pipe(
        ofType(successFetchAllSites),
        switchMap((action) => this.store.select(selectCompaniesState$).pipe(
            filter(state => state.loaded),
            take(1),
            map(state => mappingAllSitesCompanies({ companies: state.data, sites: action.sites }))
        ))
    ) });

    public successUpdateSites$ = createEffect(() => { return this.actions$.pipe(
        ofType(successUpdateSites),
        switchMap((action) => this.store.select(selectCompaniesState$).pipe(
            filter(state => state.loaded),
            take(1),
            tap(state => this.store.dispatch(mappingSomeSitesCompanies({ sites: action.payload, companies: state.data }))),
            // update children objects
            switchMap(() => this.store.select(selectParentFamiliesBySitesIds$(action.payload.map(site => site.id))).pipe(take(1))),
            map((parentFamilies) => successUpdateParentFamilies({ payload: parentFamilies }))
        )),
    ) });


    public effectSubject: Subject<string | HttpErrorResponse>;
    constructor(
        private siteService: SiteService,
        private actions$: Actions,
        private store: Store,
        public translate: TranslateService,
        private _cacheService: CacheService
    ) {
        this.effectSubject = new Subject<string>();
    }
}
