import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/services/company.service';
import * as CompanyAction from '../actions/company.actions';
import { successUpdateSites } from '../actions/site.action';
import { selectSitesByCompanyIds$ } from '../selectors/site.selectors';
import { CacheService, ObjectStore } from 'src/app/services/cache.service';
import { Company } from 'src/app/models/company';

export type EffectResult = 'success' | 'error';
@Injectable()
export class CompanyEffects {
    public fetchAllCompanies$ = createEffect(() => { return this._actions$.pipe(
        ofType(CompanyAction.fetchAllCompanies),
        switchMap((action) => {
        const payload = action?.payload?.lastRefreshTime ?? undefined; // Default to null if payload is undefined or null
        return this._companyService.getCompanies(payload).pipe(
            switchMap((companies) => {
                    if (payload) {
                        // Delete from cache and update cache sequentially
                        return this._cacheService.deleteFromCache(companies.deletedData, ObjectStore.Company).pipe(
                            switchMap(() => this._cacheService.updateCache([...companies.updatedData, ...companies.createdData], ObjectStore.Company, true)),
                            map((updatedCompanies : Company[]) => CompanyAction.successFetchAllCompanies({ payload: updatedCompanies })),
                            catchError(() => of(CompanyAction.errorFetchAllCompanies()))
                        );
                    } else {
                        // Directly cache and return the createdData
                        this._cacheService.cacheState(companies.createdData, ObjectStore.Company);
                        return of(CompanyAction.successFetchAllCompanies({ payload: companies.createdData }));
                    }
                }),
                catchError(() => of(CompanyAction.errorFetchAllCompanies()))
            );
        })
    );
 });


    public successUpdateCompanies$ = createEffect(() => { return this._actions$.pipe(
        ofType(CompanyAction.successUpdateCompanies),
        // update children
        switchMap((action) => this.store.select(selectSitesByCompanyIds$(action.payload.map(company => company.id))).pipe(
            take(1),
            map((sites) => successUpdateSites({
                payload: sites,
            }))
        )),
    ) });



    public effectSubject: Subject<EffectResult>;
    constructor(
        private _companyService: CompanyService,
        private _actions$: Actions,
        private store: Store,
        private _cacheService: CacheService,
    ) {
        this.effectSubject = new Subject<EffectResult>();
    }
}
