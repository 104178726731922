import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { KpiFamily, KpiService, KpiTimeInZone } from 'src/app/services/kpi.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { MatTabGroup } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import { contextIsValid$, selectUserSelectedContext$ } from 'src/app/store/selectors/filter-context.selectors';

@Component({
    selector: 'app-kpi',
    templateUrl: './kpi.component.html',
    styleUrls: ['./kpi.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('filterPanel', { static: false }) private _filterPanel: ElementRef<HTMLDivElement>;
    @ViewChild('kpiPage', { static: false }) private _kpiPage: ElementRef<HTMLDivElement>;
    @ViewChild('matTabGroup', { static: false }) private _matTabGroup: MatTabGroup;
    private _filterPanelResizeObserver: ResizeObserver;
    private _filterPanelHeight$ = new Subject<number>();
    private _subscription: Subscription = new Subscription();

    public kpiFamily$: Observable<KpiFamily> = this._kpiService.kpiFamily$.pipe(
        filter(v => !!v),
        tap(() => this._cd.markForCheck()),
    );
    public kpiTimeInZone$: Observable<KpiTimeInZone> = this._kpiService.kpiTimeInZone$.pipe(
        filter(v => !!v),
        tap(() => this._cd.markForCheck()),
    );
    public loading$ = this._kpiService.computing$.pipe(
        distinctUntilChanged(),
    );
    public isshowOrHideFilter = true;
    public tabContentHeight: string;
    public contextIsValid$ = this.store.select(contextIsValid$);

    constructor(
        public translate: TranslateService,
        private store: Store,
        private _kpiService: KpiService,
        private _zone: NgZone,
        private _cd: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        // Only load data if a context is selected
        this.store.select(selectUserSelectedContext$).pipe(
            filter(v => !!v),
            take(1),
        ).subscribe(() => {
            this.getDataFromBack();
        });
    }

    ngAfterViewInit() {
        this._resizePageOnFilterPanel();
    }

    /**
     * Listen to the height resize of the filter panel and resize the height of the content accordingly
     * See https://christiankohler.net/how-to-use-resizeobserver-with-angular
     */
    private _resizePageOnFilterPanel(): void {
      this._filterPanelResizeObserver = new ResizeObserver(([panel]) => {
        this._filterPanelHeight$.next(+panel.contentRect.height);
      });
      this._filterPanelResizeObserver.observe(this._filterPanel.nativeElement);
      this._subscription.add(
        this._filterPanelHeight$.pipe(
          // Wait for animation to complete
          debounceTime(250),
          // Only when the value changed (the width change will emit a value)
          distinctUntilChanged(),
        ).subscribe(
          height => {
            // Trigger change detection
            this._zone.run(() => {
              // component height
              const pageHeight = +this._kpiPage.nativeElement.getBoundingClientRect().height;
              // tabs header height
              const tabsEl = <HTMLElement>this._matTabGroup._elementRef.nativeElement?.querySelector('mat-tab-header');
              const tabsHeight = +tabsEl.getBoundingClientRect().height;
              // Compute tabs content height
              this.tabContentHeight = `${(pageHeight - tabsHeight - height).toFixed(0)}px`;
            });
          }
        )
      );
    }

    /**
     * Send API request to get kpi data from S3 file
     */
    private getDataFromBack(): void {
        this._kpiService.checkForAchievement().subscribe();
    }

    /**
     * Close or open the filter panel
     * @param value Boolean to open or close the filter panel
     */
    public showOrHideFilter(value: boolean): void {
        this.isshowOrHideFilter = value;
    }

    ngOnDestroy(): void {
        // Stop listening to the filter panel resize
        this._filterPanelResizeObserver.unobserve(this._filterPanel.nativeElement);
        this._subscription.unsubscribe();
    }
}
