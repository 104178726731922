import { createAction, props } from '@ngrx/store';
import { Company } from 'src/app/models/company';

export const fetchAllCompanies = createAction('[company] fetchAll COMPANIES',(payload?: { lastRefreshTime: string }) => ({ payload }));
export const successFetchAllCompanies = createAction('[company] success fetch all COMPANIES', props<{ payload: Company[] }>());

export const successCreateCompanies = createAction('[company] success create COMPANIES', props<{ payload: Company[] }>());

export const successUpdateCompanies = createAction('[company] success update COMPANIES', props<{ payload: Company[] }>());

export const successDeleteCompanies = createAction('[company] success delete COMPANIES', props<{ payload: Company[] }>());

export const errorFetchAllCompanies = createAction('[company] failure COMPANIES');

export const fetchDeltaCompanyCache = createAction('[Cache] fetch all companies from Delta for cache', props<{ payload: { lastRefreshTime: string } }>());
export const errorFetchDeltaCompanyCache = createAction('[Cache] Error fetch all companies from Delta for cache');
