import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from '../../components/shared/snackbar/snackbar';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import * as featureActions from '../actions/zone.action';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { Zone } from 'src/app/models/zone';
import { ZoneService } from 'src/app/services/zone.service';
import { Store } from '@ngrx/store';
import { selectSiteState$ } from '../selectors/site.selectors';
import {
    errorFetchAllZones,
    errorUpdateZone,
    fetchAllZones,
    mapZonesFamilies,
    successFetchAllZones,
    updateZone,
    createZone,
    successZoneCreation,
    successDeleteZone,
    editZone,
    successZoneEdit,
    editZoneError,
    errorDeleteZone,
    successUpdateZone,
} from '../actions/zone.action';
import { selectFamilyRightState$ } from '../selectors/familyRight.selectors';
import { CacheService, ObjectStore } from 'src/app/services/cache.service';

export interface EffectResult {
  result: 'success' | 'error';
  zone?: Zone;
}
@Injectable()
export class ZoneEffects {

    public fetchAllZones$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(fetchAllZones),
            switchMap((action) => {
                const payload = action?.payload?.lastRefreshTime ?? undefined;

                return combineLatest([
                    of(payload),
                    this.store.select(selectSiteState$).pipe(
                        filter(({ loaded }) => loaded),
                        map(({ data }) => data),
                        take(1),
                    ),
                    this.store.select(selectFamilyRightState$).pipe(
                        filter((state) => state.loaded),
                        map(state => state.data),
                        take(1),
                    )
                ]);
            }),
            switchMap(([payload, sites, families]) => {
                return this._zoneService.getZones(payload).pipe(
                    switchMap(zones => {
                        if (zones) {
                            if (payload) {
                                return this._cacheService.deleteFromCache(zones.deletedData, ObjectStore.Zone).pipe(
                                    switchMap(() => {
                                        return this._cacheService.updateCache([...zones.updatedData, ...zones.createdData], ObjectStore.Zone, true).pipe(
                                            map(updatedZones => ({ zones: updatedZones, sites, families })),
                                            catchError(() => {
                                                console.log("In the error");
                                                return of({ zones: zones.createdData, sites, families })
                                            })
                                        );
                                    })
                                );
                            } else {
                                this._cacheService.cacheState(zones.createdData, ObjectStore.Zone);
                                return of({ zones: zones.createdData, sites, families });
                            }
                        } else {
                            console.error('Expected zones to be an array of Zone, but received:', zones);
                            return of({ zones: [], sites, families });
                        }
                    })
                );
            }),
            map(({ zones, sites, families }) => successFetchAllZones({ zones, sites, families })),
            catchError(() => of(errorFetchAllZones()))
        );
    });
  public successFetchAllZones$ = createEffect(() => { return this._actions$.pipe(
    ofType(successFetchAllZones),
    map((action) => ({
      ...action,
      zones: action.zones.map(
        zone => {
          const [parentZone] = action.zones.filter(pfZone => pfZone.id === zone.parent);
          const { name, company } = action.sites.find(site => site.id === zone.siteId) ?? {};
          return <Zone>{
            ...zone,
            site: name ?? '',
            company: company ?? '',
            parentZone: parentZone?.name
          };
        }
      )
    })),
    map(({ zones, families }) => mapZonesFamilies({ zones, families })),
  ) });

  public updateZone$ = createEffect(() => { return this._actions$.pipe(
    ofType(updateZone),
    switchMap((actions) => this._zoneService.editZone(actions.zoneId, actions.zone).pipe(
      map((zone: Zone) => {
        this.store.dispatch(featureActions.updateZoneToCache({ payload: { zone: zone } }));
        const text: string = this.translate.instant('BANNER_SUCCESS_EDIT', { value: zone.name });
        this._snackbar.open(text, 'green-snackbar', 5000);
        this.effectSubject.next({
            result: 'success',
        });
        return featureActions.successUpdateZone({ zone: zone });
      }),
      catchError((error) => {
        this._errorTranslationService.handleError(error, 'BANNER_FAIL_EDIT');
        this.effectSubject.next({
          result: 'error'
        });
        return of(errorUpdateZone());
      }))
    )) });

    public createZone$ = createEffect(() => { return this._actions$.pipe(
        ofType(createZone),
        switchMap((actions) => this._zoneService.createZone$(actions.payload).pipe(
            map((zone: Zone) => {
              this.effectSubject.next({
                result: 'success',
                zone: zone
              });
                return successZoneCreation({ payload: zone });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_EDIT');
                this.effectSubject.next({
                  result: 'error',
                });
                return of(errorUpdateZone());
            }))
        )
    ) });

    public editZone$ = createEffect(() => { return this._actions$.pipe(
        ofType(editZone),
        switchMap((actions) => this._zoneService.updateZone$(actions.payload).pipe(
            map((zone: Zone) => {

                this.effectSubject.next({
                  result: 'success',
                  zone: zone
                });
                return successZoneEdit({ payload: zone });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_EDIT');
                this.effectSubject.next({
                  result: 'error',
                });
                return of(editZoneError());
            }))
        )
    ) });

    public deleteZone$ = createEffect(() => { return this._actions$.pipe(
        ofType(featureActions.deleteZone),
        switchMap((action) => this._zoneService.deleteZone$(action.zoneId).pipe(
            map(() => {
              this.effectSubject.next({
                result: 'success'
              });
                return successDeleteZone({ zoneId: action.zoneId });
            }),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_DELETE');
                this.effectSubject.next({
                  result: 'error',
                });
                return of(errorDeleteZone());
            })
        ))
    ) });

    protected successUpdateZone$ = createEffect(() => { return this._actions$.pipe(
        ofType(successUpdateZone),
        switchMap((actions) => {
            return this.store.select(selectFamilyRightState$).pipe(
                filter(data => data.loaded),
                map(({ data }) => {
                    return mapZonesFamilies({ zones: [actions.zone], families: data });
                })
            );
        })
    ) });


    public updateZoneToCache$ = createEffect(() =>
    { return this._actions$.pipe(
        ofType(featureActions.updateZoneToCache),
        switchMap(action =>
            this._cacheService.updateCache([action.payload.zone], ObjectStore.Zone).pipe(
                map(() => featureActions.successUpdateZoneToCache()),
                catchError(error => {
                    return of(featureActions.errorUpdateZoneToCache());
                })
            )
        )
    ) }
);

public effectSubject: Subject<EffectResult>;

  constructor(
    private _zoneService: ZoneService,
    private _actions$: Actions,
    private _snackbar: SnackbarComponent,
    public translate: TranslateService,
    private _errorTranslationService: ErrorTranslationService,
    private store: Store,
    private _cacheService: CacheService
  ) {
    this.effectSubject = new Subject<EffectResult>();
  }
}
