import { createAction, props } from '@ngrx/store';
import { FamilyRight } from 'src/app/models/family';
import { Site } from 'src/app/models/site';
import { Zone, ZoneToCreate, ZoneAttributesUpdate, ZonePositionUpdate } from 'src/app/models/zone';
import { Cache } from './../../models/cache';

export const fetchAllZones = createAction('[Zone] fetch all Zones',(payload?: { lastRefreshTime: string }) => ({ payload }));
export const successFetchAllZones = createAction('[Zone] Success fetch all Zones', props<{ zones: Zone[], sites: Site[], families: FamilyRight[] }>());
export const errorFetchAllZones = createAction('[Zone] Error Zones');

export const updateZone = createAction('[Zone] edit zone', props<{ zoneId: string, zone: ZoneAttributesUpdate }>());
export const successUpdateZone = createAction('[Zone] Success edit zone', props<{ zone: Zone }>());
export const errorUpdateZone = createAction('[Zone] Error edit zone');

export const deleteZone = createAction('[Zone] Delete Zone', props<{ zoneId: string }>());
export const successDeleteZone = createAction('[Zone] Success Delete Zone', props<{zoneId: string}>());
export const errorDeleteZone = createAction('[Zone] Error Delete Zone');

export const createZone = createAction('[Zone] Create Zone', props<{ payload: ZoneToCreate }>());
export const successZoneCreation = createAction('[Zone] Success Create Zone', props<{ payload: Zone }>());
export const createZoneError = createAction('[Zone] Error Create Zones');

export const editZone = createAction('[Zone] Edit Zone', props<{ payload: ZonePositionUpdate }>());
export const successZoneEdit = createAction('[Zone] Success Edit Zone', props<{ payload: Zone }>());
export const editZoneError = createAction('[Zone] Error Edit Zones');


export const mapZonesFamilies = createAction('[Zone] Success map zone families', props<{ zones: Array<Zone>, families: Array<FamilyRight> }>());

export const fetchDeltaZoneCache = createAction('[Cache] fetch updated Zone to cache', props<{ payload: { lastRefreshTime: string } }>());
export const errorFetchDeltaZoneCache = createAction('[Cache] Error *in fetch update Zone');

export const updateZoneToCache = createAction('[Cache] update Zone to cache', props<{ payload: { zone: Zone } }>());
export const successUpdateZoneToCache = createAction('[Cache] success update Zone to cache');
export const errorUpdateZoneToCache = createAction('[Cache] Error update Zone to cache');

