import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, combineLatest, Subject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as featureActions from '../actions/familyRight.action';
import { TranslateService } from '@ngx-translate/core';
import { FamilyService } from 'src/app/services/family.service';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { successCreateFamilies } from '../actions/family.action';
import { Store } from '@ngrx/store';
import { selectProfileParentFamilies$ } from '../selectors/profile.selectors';
import { selectParentFamilyById$ } from '../selectors/parent-family.selectors';
import { Family } from 'src/app/models/family';
import { CacheService, ObjectStore } from 'src/app/services/cache.service';
import { successFetchAllFamilies } from '../actions/family.action';

export type EffectResult = 'success' | 'error';
@Injectable()
export class FamilyRightEffects {

    public LoadFamilyRight$: Observable<featureActions.Actions> = createEffect(() => {
        return this._actions$.pipe(
            ofType<featureActions.LoadFamilyRight>(featureActions.ActionTypes.LOAD_FAMILY_RIGHT),
            switchMap(({ payload }) => {
                const loadAllFamilyRight$ = payload && payload.lastRefreshTime ?
                    this._familyService.getFamiliesRights(payload.lastRefreshTime).pipe(
                        tap(families => {
                            this._cacheService.deleteFromCache(families.deletedData, ObjectStore.FamilyRight).pipe(
                                switchMap(() =>
                                    this._cacheService.updateCache(families.updatedData, ObjectStore.FamilyRight, true).pipe(
                                        map(() => new featureActions.SuccessFamilyRight(families.updatedData)),
                                        catchError(error => of(error))
                                    )
                                )
                            ).subscribe();
                        }),
                    ) :
                    this._familyService.getFamiliesRights().pipe(
                        tap(families => this._cacheService.cacheState(families.createdData, ObjectStore.FamilyRight)),
                        map(families => new featureActions.SuccessFamilyRight(families.createdData)),
                        catchError(error => of(error))
                    );

                return loadAllFamilyRight$.pipe(
                    catchError(error => {
                        this._errorTranslationService.handleError(error, this.translate.instant('BANNER_FAIL_FETCH_RIGHTS'));
                        return of(error);
                    })
                );
            })
        );
    });

    // fetchDeltaFamilyRightCache$ = createEffect(() => {
    //     return this._actions$.pipe(
    //         ofType(featureActions.fetchDeltaFamilyRightCache),
    //         switchMap((action) => this._familyService.getFamiliesRightsByDelta(action.payload.lastRefreshTime).pipe(
    //             mergeMap(family => this._cacheService.deleteFromCache(family.deletedData, ObjectStore.FamilyRight).pipe(
    //                 mergeMap(() => this._cacheService.updateCache(family.updatedData, ObjectStore.FamilyRight, true).pipe(
    //                     map((updatedFamily: Family[]) => successFetchAllFamilies({ payload: updatedFamily })),
    //                     catchError(error => {
    //                         this.effectSubject.next(error);
    //                         return of(new featureActions.ErrorFamilyRight());
    //                     })
    //                 ))
    //             ))
    //         ))
    //     )
    // });

    public updateUserRightOnFamilyCreation$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(successCreateFamilies),
            switchMap(({ family }) => combineLatest([
                this.store.select(selectProfileParentFamilies$),
                this.store.select(selectParentFamilyById$(family.parentFamilyId)),
            ]).pipe(
              map(([rights, parentFamily]) => {
                    const parentFamilyRight = rights.find(
                        ({ id }) => id === family.parentFamilyId
                    );
                    return new featureActions.SuccessUpdateFamilyRight({
                        id: family.id,
                        level: 'manager',
                        name: family.name,
                        parentFamily: {
                            id: parentFamily.id,
                            company: parentFamily.site?.company,
                            level: parentFamilyRight.level,
                            name: parentFamily.name,
                            requestId: '',
                            site: parentFamily.site,
                            siteId: parentFamily.siteId,
                        },
                        requestId: '',
                        scope: family.scope,
                    });
                })
            )),
        );
    });


    updateCacheFamilyRight$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(featureActions.updateCacheFamilyRight),
            switchMap(action =>
                this._cacheService.updateCache([action.payload], ObjectStore.FamilyRight).pipe(
                    map(() => featureActions.successUpdateCacheFamilyRight()),
                    catchError(error => {
                        return of(featureActions.errorUpdateCacheFamilyRight());
                    })
                )
            )
        )
    }
    );

    public effectSubject: Subject<EffectResult>;
    constructor(
        private _familyService: FamilyService,
        private _actions$: Actions,
        public translate: TranslateService,
        private _errorTranslationService: ErrorTranslationService,
        private readonly store: Store,
        private _cacheService: CacheService
    ) {
        this.effectSubject = new Subject<EffectResult>();
    }
}
