import { createAction, props } from '@ngrx/store';
import { Family, FamilyRight } from 'src/app/models/family';
import { FamilyRightCache } from 'src/app/models/user-right';

export enum ActionTypes {
    LOAD_FAMILY_RIGHT = '[Family] Load Family Right',
    SUCCESS_FAMILY_RIGHT = '[Family] Success Family Right',
    SUCCESS_UPDATE_FAMILY_RIGHT = '[Family] Success Update Family Right',
    ERROR_FAMILY_RIGHT = '[Family] Error Family Right'
}

export class LoadFamilyRight {
    readonly type = ActionTypes.LOAD_FAMILY_RIGHT;
    constructor(public payload?: { lastRefreshTime: string }) {}
}

export class SuccessFamilyRight {
    readonly type = ActionTypes.SUCCESS_FAMILY_RIGHT;
    constructor(public payload: Array<FamilyRight>) {
    }
}

export class SuccessUpdateFamilyRight {
    readonly type = ActionTypes.SUCCESS_UPDATE_FAMILY_RIGHT;
    constructor(public payload: FamilyRight) {
    }
}

export class ErrorFamilyRight {
    readonly type = ActionTypes.ERROR_FAMILY_RIGHT;
}

export type Actions =
    LoadFamilyRight |
    SuccessFamilyRight |
    ErrorFamilyRight |
    SuccessUpdateFamilyRight;

    export const updateCacheFamilyRight = createAction('[Cache] update Family Right to cache', props<{ payload: FamilyRightCache }>());
    export const successUpdateCacheFamilyRight = createAction('[Cache] success update Family Right to cache');
    export const errorUpdateCacheFamilyRight = createAction('[Cache] Error update Family Right to cache');


    export const deleteCacheFamilyRight = createAction('[Cache] delete Family Right from cache', props<{ payload: Family }>());
    export const successDeleteCacheFamilyRight = createAction('[Cache] success delete Family Right from cache');
    export const errorDeleteCacheFamilyRight = createAction('[Cache] Error delete Family Right from cache');

    // export const fetchDeltaFamilyRightCache = createAction('[Cache] update Family Right to cache', props<{ payload: { lastRefreshTime: string } }>());
    // export const errorFetchDeltaFamilyRightCache = createAction('[Cache] Error update Family Right to cache');
