import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { createFamily, fetchAllFamilies, mappingAllFamiliesParentFamilies, successFetchAllFamilies, successCreateFamilies, mappingSomeFamiliesParentFamilies, updateFamily, successUpdateFamilies, deleteFamily, successDeleteFamilies, errorDeleteFamilies, errorUpdateFamilies, errorCreateFamilies, deleteCacheFamily, updateCacheFamily, successUpdateCacheFamily, errorUpdateCacheFamily, successDeleteCacheFamily } from '../actions/family.action';
import { Store } from '@ngrx/store';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { FamilyService } from 'src/app/services/family.service';
import { Family } from 'src/app/models/family';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { selectParentFamilyById$, selectParentFamilyState$ } from '../selectors/parent-family.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { selectAssetsByFamiliesIds } from '../selectors/asset.selectors';
import { successUpdateAssets } from '../actions/asset.actions';
import { deleteFamilyFromUserFilter } from '../actions/filter-context.action';
import { contextIsValid$, selectUserSelectedContext$ } from '../selectors/filter-context.selectors';
import { addFamilyProfile } from '../actions/profile.action';
import { noop } from '../actions/_shared.action';
import { CacheService, ObjectStore } from 'src/app/services/cache.service';
import * as featureActions from '../actions/user-right.action';
import { selectFamiliesByParentFamilyIds$ } from '../selectors/family.selectors';

@Injectable()
export class FamilyEffects {

    fetchAllFamilies$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(fetchAllFamilies),
            switchMap(({ payload }) => {
            const fetchFamilies$ = payload?.lastRefreshTime ?
                this._familyService.getFamilies(payload.lastRefreshTime).pipe(
                    switchMap(family =>
                        this._cacheService.deleteFromCache(family.deletedData, ObjectStore.Families).pipe(
                            switchMap(() =>
                                this._cacheService.updateCache(family.updatedData, ObjectStore.Families, true).pipe(
                                    map((updatedData: Family[]) => successFetchAllFamilies({ payload: updatedData })),
                                    catchError(error => of(error))
                                )
                            )
                        )
                    ),
                ) :
                this._familyService.getFamilies().pipe(
                    tap(family => this._cacheService.cacheState(family.createdData, ObjectStore.Families)),
                    map(family => successFetchAllFamilies({ payload: family.createdData })),
                    catchError(error => of(error))
                );
            return fetchFamilies$.pipe(
                catchError(error => {
                    console.error('Error fetching families:', error);
                    return of(error);
                })
            );
        })
        )
    });

    public successFetchAllFamilies$ = createEffect(() => { return this._actions$.pipe(
        ofType(successFetchAllFamilies),
        switchMap((action) => this.store.select(selectParentFamilyState$).pipe(
            filter(state => state.loaded),
            take(1),
            map((state) => mappingAllFamiliesParentFamilies({
                families: action.payload,
                parentFamilies: state.data.parentFamilies
            }))
        ))
    ) });

    public createFamily$ = createEffect(() => { return this._actions$.pipe(
        ofType(createFamily),
        switchMap((action) => {
            return this._familyService.createFamily(action.family, action.family.parentFamilyId).pipe(
              switchMap((newFamily) => {
                if (action.base64picture && action.base64picture !== '' && newFamily.id) {
                  return this._familyService.createPictureFamily(action.base64picture, newFamily.id).pipe(
                    map(() => newFamily
                ));
                }
                return of(newFamily);
              }),
              concatMap((newFamily) => {
                this.store.dispatch(updateCacheFamily({ payload: newFamily }));

                this.effectSubject.next('success');
                return of(successCreateFamilies({ family: newFamily }));
              }),
              catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
                this.effectSubject.next('error');
                return of(errorCreateFamilies());
              })
            );
        })
    ) });

    public successCreateFamilies$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(successCreateFamilies),
            switchMap(({ family }) => combineLatest([
                this.store.select(contextIsValid$),
                this.store.select(selectUserSelectedContext$),
                this.store.select(selectParentFamilyById$(family.parentFamilyId)),
            ]).pipe(
                take(1),
                filter(([ valid ]) => valid),
                map(([, context, parentFamily]) => {
                    // If families are set, do nothing, but display message
                    let text = this._translate.instant('BANNER_SUCCESS_CREATE', { item: family.name });
                    if (context?.families?.length) {
                        text += `\n${this._translate.instant('CONTEXT_FILTERED_BY__UPDATE_FOR_VALUE', {
                            type: this._translate.instant('ASSET_FAMILY')?.toLowerCase(),
                        })}`;
                        this._snackBar.open(text, 'green-snackbar', 5000);
                        return noop();
                    }
                    this._snackBar.open(text, 'green-snackbar', 5000);
                    // User is below asset limit, or has not filtered by family => add family
                    return mappingSomeFamiliesParentFamilies({ families: [family], parentFamilies: { [family.parentFamilyId]: parentFamily } });
                }),
            )),
        );
    });

    public updateProfileOnCreation$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(successCreateFamilies),
            switchMap(({ family }) => combineLatest([
                this.store.select(contextIsValid$),
                this.store.select(selectUserSelectedContext$),
            ]).pipe(
                take(1),
                filter(([ valid ]) => valid),
                map(([, context]) => {
                    if (!context?.families?.length) {
                        return addFamilyProfile({ profileFamily: {
                            id: family.id,
                            level: 'manager',
                            parent: family.parentFamilyId,
                        } });
                    }
                }),
            )),
        );
    });

    public updateFamily$ = createEffect(() => { return this._actions$.pipe(
        ofType(updateFamily),
        switchMap((action) => {
            return this._familyService.updateFamily(action.family, action.family.id).pipe(
              concatMap((familyUpdated: Family) => {
                this.store.dispatch(updateCacheFamily({ payload: familyUpdated }));
                const text: string = this._translate.instant('BANNER_SUCCESS_EDIT', { value: action.family.name });
                this._snackBar.open(text, 'green-snackbar', 5000);
                this.effectSubject.next('success');
                return of(successUpdateFamilies({
                  payload: [familyUpdated]
                }));
              }),
              catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_EDIT');
                this.effectSubject.next('error');
                return of(errorUpdateFamilies());
              })
            );
        })
    ) });

    public successUpdateFamilies$ = createEffect(() => { return this._actions$.pipe(
        ofType(successUpdateFamilies),
        switchMap((action) => this.store.select(selectParentFamilyState$).pipe(
            filter(state => state.loaded),
            take(1),
            tap(state => this.store.dispatch(mappingSomeFamiliesParentFamilies({
                families: action.payload,
                parentFamilies: state.data.parentFamilies
            }))),
            // update children
            switchMap(() => this.store.select(selectAssetsByFamiliesIds(action.payload.map(family => family.id))).pipe(take(1))),
            map((assets) => successUpdateAssets({ payload: assets }))
        ))
    ) });

    public deleteFamily$ = createEffect(() => { return this._actions$.pipe(
        ofType(deleteFamily),
        switchMap((action) => this._familyService.deleteFamily(action.payload).pipe(map(() => action))),
        tap(() => this.effectSubject.next('success')),
        map((action) => {
            this.store.dispatch(deleteFamilyFromUserFilter({ familyId: action.payload}));
            return successDeleteFamilies({ payload: [{ id: action.payload }] });
        }),
        catchError(error => {
            this.effectSubject.next(error);
            return of(errorDeleteFamilies());
        })
    ) });


    deleteCacheFamily$ = createEffect(() =>
        { return this._actions$.pipe(
          ofType(deleteCacheFamily),
          switchMap(action =>
            this._cacheService.deleteFromCache([action.payload],ObjectStore.Families).pipe(
              map(() => successDeleteCacheFamily()),
              catchError(error => {
                return of(errorUpdateCacheFamily());
              })
            )
          )
        ) }
      );


    public updateCacheFamily$ = createEffect(() =>  { return this._actions$.pipe(
        ofType(updateCacheFamily),
        switchMap(action =>
          this._cacheService.updateCache([action.payload], ObjectStore.Families).pipe(
            map(() => successUpdateCacheFamily()),
            catchError(error => {
              return of(errorUpdateCacheFamily());
            })
          )
        )
      ) }
    );
    public removeFamiliesBasedOnRemoveRights$ = createEffect(() => {
        return this._actions$.pipe(
            ofType<featureActions.SuccessDeleteMyRights>(featureActions.ActionTypes.SUCCESS_DELETE_MY_RIGHT),
            switchMap(({ payload }) => {
                if (payload.removeRight.isParentFamily) {
                    return this.store.select(selectFamiliesByParentFamilyIds$([payload.removeRight.deleteMyRight.id])).pipe(
                        take(1),
                        switchMap(families => {
                            const deleteFamilyIds = families.map(family => family.id);

                            // Call updateFromCache here for multiple IDs
                            return this._cacheService.deleteFromCache(families, ObjectStore.Families).pipe(
                                map(() =>
                                    successDeleteFamilies({ payload: deleteFamilyIds.map(id => ({ id })) })
                                ),
                                catchError(error => of(errorDeleteFamilies()))
                            );
                        })
                    );
                } else {
                    // Call updateFromCache here for a single ID
                    return this._cacheService.deleteFromCache([payload.removeRight.deleteMyRight], ObjectStore.Families).pipe(
                        map(() =>
                            successDeleteFamilies({
                                payload: [{ id: payload.removeRight.deleteMyRight.id }]
                            })
                        ),
                        catchError(error => of(errorDeleteFamilies()))
                    );
                }
            }),
            catchError(error => of(errorDeleteFamilies()))
        );
    });


    public effectSubject: Subject<string | HttpErrorResponse>;
    constructor(
        private _familyService: FamilyService,
        private _actions$: Actions,
        private store: Store,
        private _snackBar: SnackbarComponent,
        private _translate: TranslateService,
        private _errorTranslationService: ErrorTranslationService,
        private _cacheService: CacheService
    ) {
        this.effectSubject = new Subject<string>();
    }
}
